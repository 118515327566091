import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { CircularProgress, useMediaQuery } from '@material-ui/core';
import {
  useParams
} from "react-router-dom";
import { Collection } from 'common-ts/dist/models/Product';
import { useAppSelector } from '../reduxhooks';
import { BlockCarousel, BlockProductArrayCenterData, renderBlockElement, RenderBlockElementProps } from './PageComponentElementBlocks';
import PageItemBreakpoint from './PageComponentElementItemBreakpointSize';
import InfiniteScroll from 'react-infinite-scroll-component';
import React, { useEffect } from 'react';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  productListBody: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    
    marginTop: '40px', // This is same size as category bar
    [theme.breakpoints.up('sm')]: {
      marginTop: '12px',
    },
  },
}),
);

interface Props {
  categoryName: string
}

export default function PageProductList({ categoryName }: Props) {
  const productsDictionary = useAppSelector(state => state.productsList.productsDictionary);
  const productDatabase = useAppSelector(state => state.productsList);
  const overlayFlowVersionView = useAppSelector(state => state.overlayFlowVersionView);
  const classes = useStyles();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const completeScrollItems: JSX.Element[][] = [];
  const completeScrollItemsCompleteDict: {[key:string]: boolean} = {};

  // If categoryName equals "landing", the COLLECTION query key and SORT query key is used.
  // Then we add all products in default (newest arrival) order.
  // If categoryName equals "start-*", we iterate items in that key,
  // then add all products in default (newest arrival) order.
  // If categoryName matches to a certain category key, we iterate items in that key,
  // then add any items that have the 'category' field equal to categoryName in default order.
  // If categoryName doesn't match to a certain category key, 
  // we add any items that have the 'category' field equal to categoryName in default order.
  // If categoryName equals "", we return all products in default (newest arrival) order.

  // FIRST ITEM DEPENDS ON THE COLLECTION QUERY KEY
  if (categoryName === "landing" && overlayFlowVersionView.collectionQueryKey) {
    const collectionQueryKeyId = overlayFlowVersionView.collectionQueryKey;
    if (productDatabase.collectionsDictionary[collectionQueryKeyId]) {
      completeScrollItemsCompleteDict[collectionQueryKeyId] = true;

      let collectionDetail: Collection = productDatabase.collectionsDictionary[collectionQueryKeyId];

      let collectionDiscoveryView: JSX.Element[] = [];
      for (let i = 0; i < collectionDetail.discoveryListOfElements.length; i++) {
        let blockData = collectionDetail.discoveryListOfElements[i];
        let elementData: RenderBlockElementProps = {
          code: isDesktop ? blockData.elementDesktopCode : blockData.elementMobileCode,
          contentJson: isDesktop ? blockData.elementDesktopContentJson : blockData.elementMobileContentJson,
        }
        let element = renderBlockElement(elementData);
        collectionDiscoveryView.push(element);
      }
      completeScrollItems.push(collectionDiscoveryView);
    }
  }

  const discoveryCollectionsOrder = 
    (categoryName === "landing" && overlayFlowVersionView.sortQueryKey && productDatabase.discoveryCollectionsOrderDict[overlayFlowVersionView.sortQueryKey]) ? 
    productDatabase.discoveryCollectionsOrderDict[overlayFlowVersionView.sortQueryKey] : 
    (categoryName && productDatabase.discoveryCollectionsOrderDict[categoryName]) ? 
    productDatabase.discoveryCollectionsOrderDict[categoryName] : 
    []; // We use the default order

  // ITERATE ACCORDING TO ORDER KEY
  for (let m = 0; m < discoveryCollectionsOrder.length; m++) {
    const collectionId = discoveryCollectionsOrder[m];
    let collectionDetail: Collection = productDatabase.collectionsDictionary[collectionId];

    // WE DONT FILTER
    if (completeScrollItemsCompleteDict[collectionId]) {
      continue;
    }
    completeScrollItemsCompleteDict[collectionId] = true;

    let collectionDiscoveryView: JSX.Element[] = [];
    for (let i = 0; i < collectionDetail.discoveryListOfElements.length; i++) {
      let blockData = collectionDetail.discoveryListOfElements[i];
      let elementData: RenderBlockElementProps = {
        code: isDesktop ? blockData.elementDesktopCode : blockData.elementMobileCode,
        contentJson: isDesktop ? blockData.elementDesktopContentJson : blockData.elementMobileContentJson,
      }
      let element = renderBlockElement(elementData);
      collectionDiscoveryView.push(element);
    }
    completeScrollItems.push(collectionDiscoveryView);
  }

  // ITERATE LAST ITEMS
  for (let m = 0; m < productDatabase.discoveryCollectionsOrder.length; m++) {
    const collectionId = productDatabase.discoveryCollectionsOrder[m];
    let collectionDetail: Collection = productDatabase.collectionsDictionary[collectionId];

    // IF CATEGORY NAME EXISTS, WE FIRST CHECK IF IT IS IN THE CATEGORY.
    if (!categoryName.includes('start') && !categoryName.includes('landing') &&
      categoryName && categoryName !== collectionDetail.category) {
      continue;
    }
    if (completeScrollItemsCompleteDict[collectionId]) {
      continue;
    }
    completeScrollItemsCompleteDict[collectionId] = true;

    let collectionDiscoveryView: JSX.Element[] = [];
    for (let i = 0; i < collectionDetail.discoveryListOfElements.length; i++) {
      let blockData = collectionDetail.discoveryListOfElements[i];
      let elementData: RenderBlockElementProps = {
        code: isDesktop ? blockData.elementDesktopCode : blockData.elementMobileCode,
        contentJson: isDesktop ? blockData.elementDesktopContentJson : blockData.elementMobileContentJson,
      }
      let element = renderBlockElement(elementData);
      collectionDiscoveryView.push(element);
    }
    completeScrollItems.push(collectionDiscoveryView);
  }

  useEffect(() => {
    setTimeout(() => {
      setScrollObjectState({
        items: [completeScrollItems[0]],
        hasMore: true,
      });
    }, 1500);
  }, [productDatabase.discoveryCollectionsOrder, productDatabase.discoveryCollectionsOrderDict, categoryName, 
    overlayFlowVersionView.collectionQueryKey, overlayFlowVersionView.sortQueryKey]);

  const showDiscoveryViewInCategory = true;
  const [scrollObjectState, setScrollObjectState] = React.useState({
    items: [completeScrollItems[0]],
    hasMore: true
  });

  const fetchMoreData = () => {
    if (scrollObjectState.items && scrollObjectState.items.length >= completeScrollItems.length) {
      setScrollObjectState({ 
        items: scrollObjectState.items,
        hasMore: false 
      });
      return;
    }

    // console.log("Refetching with current number of items: " + scrollObjectState.items.length);

    if (scrollObjectState.items) {
      setScrollObjectState({
        items: completeScrollItems.slice(0, scrollObjectState.items.length + 1),
        hasMore: true,
      })
    }
  };

  // Discovery view
  if (categoryName === "" || categoryName === undefined || showDiscoveryViewInCategory) {
    return (
      <Box className={classes.productListBody} id="scrollableDiv">
        <InfiniteScroll
          dataLength={scrollObjectState.items.length} //This is important field to render the next data
          next={fetchMoreData}
          hasMore={scrollObjectState.hasMore}
          loader={<div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><CircularProgress/></div>}
          endMessage={
            <p style={{ textAlign: 'center' }}>
            </p>
          }
          scrollThreshold="800px"
        >
          {
            scrollObjectState.items
          }
        </InfiniteScroll>
      </Box>
    )
  } 
  // Efficient view
  else {
    let productsInCategory: string[] = [];
    for (let i = 0; i < productDatabase.efficientProductsOrder.length; i++) {
      let productId = productDatabase.efficientProductsOrder[i]
      let item = productsDictionary[productId];
      if (categoryName && categoryName !== "") {
        if (item.category === categoryName) {
          productsInCategory.push(productId);
        }
      } else {
        productsInCategory.push(productId);
      }
    }

    let param: BlockProductArrayCenterData = {
      productIds: productsInCategory,
    
      containerWidthXs: '100%',
      containerWidthSm: '81%',
      containerWidthMd: '81%',
      containerWidthLg: '81%',
      productWidthXs: '48%',
      productWidthSm: '30%',
      productWidthMd: '30%',
      productWidthLg: '30%',
    
      containerMargin: isDesktop ? '85px 0px 60px 0px' : '65px 0px 60px 0px',
      productPadding: isDesktop ? '20px 0px 9px 0px' : '8px 0px 9px 0px',
    }

    return (
      <Box className={classes.productListBody}>
        {
          renderBlockElement({code: "BlockProductArrayCenter", contentJson: JSON.stringify(param)})
        }
      </Box>
    );

  }
}