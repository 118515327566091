import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

interface ProductVariant {
  variantName: string;
  variantPath: string;
  variantDisplayImage: string;
}

interface PageProductDetailVariantOptionsProps {
  productVariantsList: ProductVariant[];
  currentProductId: string;
}

const useStyles = makeStyles((theme) => ({
  variantOptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    cursor: 'pointer',
    width: '3vw',
    [theme.breakpoints.down('sm')]: {
      width: '12vw',
    },
  },
  variantDisplayImageContainer: {
    width: '100%',
    borderRadius: '8px',
    overflow: 'hidden',
    border: '1.2px solid #DADADA',
    padding: '0.25vw 0.25vw 0.25vw 0.25vw',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: '2.5px',
    },
    height: '3vw',
    [theme.breakpoints.down('sm')]: {
      padding: '0.75vw 0.75vw 0.75vw 0.75vw',
      height: '12vw',
    },
  },
  variantDisplayImageContainerSelected: {
    width: '100%',
    borderRadius: '8px',
    overflow: 'hidden',
    border: '1.9px solid #606060',
    padding: '0.25vw 0.25vw 0.25vw 0.25vw',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: '2.5px',
    },
    height: '3vw',
    [theme.breakpoints.down('sm')]: {
      padding: '0.75vw 0.75vw 0.75vw 0.75vw',
      height: '12vw',
    },
  },
  variantText: {
    fontWeight: 400,
    color: '#818181',
    paddingTop: '0px',
    fontSize: 'clamp(10px, 3vw, 14px)',
    whiteSpace: 'nowrap',
    overflow: 'visible',
    textWrap: 'nowrap',
    textAlign: 'left',
    width: '3vw',
    [theme.breakpoints.down('sm')]: {
      width: '12vw',
    },
  },
}));

const PageProductDetailVariantOptions: React.FC<PageProductDetailVariantOptionsProps> = ({
  productVariantsList = [],
  currentProductId,
}) => {
  const classes = useStyles();
  const history = useHistory(); 

  const handleImageClick = (variantPath: string) => {
    history.push(variantPath);
  };

  return (
    <Grid container spacing={2} alignItems="center">
      {productVariantsList.map((variant, index) => {
        return (
          <Grid item key={index}>
            <Box
              className={classes.variantOptionContainer}
              onClick={() => handleImageClick(variant.variantPath)}
            >
              <Box className={currentProductId === variant.variantPath ? classes.variantDisplayImageContainerSelected : classes.variantDisplayImageContainer}>
                <img src={variant.variantDisplayImage} alt={variant.variantName} />
              </Box>
            </Box>
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <Typography className={classes.variantText}>
          {productVariantsList?.find((value) => value.variantPath === currentProductId)?.variantName?.toUpperCase()}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PageProductDetailVariantOptions;
