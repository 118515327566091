import { Box } from '@material-ui/core';
import { Mixpanel } from '../mixpanel';
import { RegularSizeValue, RegularSizeValueShortName, SizeType } from 'common-ts/dist/models/Cart';
import { ProductOnUser } from 'common-ts/dist/models/Product';
import { ClothingType, ClothingTypeDisplayString, FitType, FitTypeDisplayString, UserClothTypeSpecificBodyMeasurements } from 'common-ts/dist/models/UserMeasurements';
import { inputMeasurementsForm, postClothingMeasurement, resetUserMeasurementDraft } from '../redux/MeasurementsRedux';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import BackButtonSegment from './OverlayViewGenerateMeasure0BackSegment';
import OverlayViewinstantSizeTeamIntroduction from './OverlayViewGenerateMeasureMethod4InstantSizeTeam0Introduction';
import OverlayViewInstantSizeTeamDetail from './OverlayViewGenerateMeasureMethod4InstantSizeTeam1Detail';
import { sendWebsiteEventMessage } from '../telegrambotevents';
import { useState } from 'react';

import OverlayViewInstantSizeTeamHeightWeight from './OverlayViewGenerateMeasureMethod1Algorithm1HeightWeight';
import OverlayViewFavoriteSelectSize from './OverlayViewGenerateMeasureMethod3Favorite0SelectSize';
import { updateHeightWeightValues, updateSelectedRegularSizeValue } from '../redux/UIOverlayMeasurementInstantSizeTeamViewRedux';
import { closeOverlayAndReset } from '../redux/UIOverlayMeasurementMethodSelectionViewRedux';


// Determines which flow is used based on starting parameters
export interface FlowSelectionData {
  clothingType: ClothingType
  fitType: FitType
  productDetail: ProductOnUser | undefined
}

// State data for flow
// Only for UI state, currentUserMeasurement is accessed within component.
export interface FlowStateData {
  currentScreen: string
  titleNumber: number,
  selectedRegularSize: RegularSizeValue,
  height: number,
  weight: number
}

// Callback to change state data for flow. 
// Only for UI state, currentUserMeasurement is accessed within component.
export interface FlowStateCallback {
  delayedDispatch: (currentScreen: string) => void
  setTitleNumber: (titleNumber: number) => void
}

//
// RENDERER 
// Master function that is used to select and pass data to the block 
// selected using the flow selection data.
//
export function FlowComponentMaster(flowSelectionData: FlowSelectionData, 
  flowStateData: FlowStateData, flowStateCallback: FlowStateCallback) {
  
  let children: JSX.Element[] = [];
  children = FlowComponentMensTops(flowSelectionData, flowStateData, flowStateCallback);
  
  // We choose the right block to use with the flow selection data.
  return (
    <Box>
      {
        children
      }
    </Box>
  );
}

function FlowComponentMensTops(flowSelectionData: FlowSelectionData, 
  flowStateData: FlowStateData, flowStateCallback: FlowStateCallback) {
  const dispatch = useAppDispatch();

  let titleNumber = -1;

  let delayedDispatch = flowStateCallback.delayedDispatch;

  let children: JSX.Element[] = [];

  switch (flowStateData.currentScreen) {
    case "instantSizeTeamIntroduction": 
      children.push(<OverlayViewinstantSizeTeamIntroduction
        handleSubmit={() => {
          Mixpanel.track('instantSizeTeamIntroductionNext', {
            'answer': 'instantSizeTeam',
          });
          delayedDispatch("instantSizeTeamSelectSize");
        }
        }/>);
      break;
    case "instantSizeTeamSelectSize":
      children.push(<BackButtonSegment
        handleBack={() => {
          Mixpanel.track('instantSizeTeamSelectSizeBack');
          delayedDispatch("instantSizeTeamIntroduction");
        }}
      />);
      children.push(<OverlayViewFavoriteSelectSize
        titleText='Pilih size terdekat dengan ukuran yang biasa kamu pakai'
        clothingType={flowSelectionData.clothingType as ClothingType}
        fitType={flowSelectionData.fitType as FitType}
        key2={flowSelectionData.clothingType + "-" + flowSelectionData.fitType}
        handleSubmit={(value) => {
          sendWebsiteEventMessage("User chooses dibuatkan tim: SIZE " + value);
          
          dispatch(updateSelectedRegularSizeValue(value))

          Mixpanel.track('instantSizeTeamSelectSizeNext', {
            'selectedSize': value,
          });

          const url = 'https://wa.me/6287813028340?text=Halo%20Instant%20Size%20Team,' + 
          '%20mau%20buat%20ukuran%20custom%20dari%20' + 
          value + '%20' + (flowSelectionData?.productDetail ? `untuk%20${flowSelectionData.productDetail.name}.` : '');
          window.open(url, '_blank');
          
          // delayedDispatch("instantSizeTeamHeightWeight");
        }}
      />);
      break;
    case "instantSizeTeamHeightWeight":
      children.push(<BackButtonSegment
        handleBack={() => {
          Mixpanel.track('instantSizeTeamHeightWeightBack');

          delayedDispatch("instantSizeTeamSelectSize");
        }}
      />);
      children.push(<OverlayViewInstantSizeTeamHeightWeight
        titleText='Masukan Tinggi dan Berat Badan kamu'
        handleSubmit={(values) => {
          Mixpanel.track('instantSizeTeamHeightWeightNext');
          dispatch(updateHeightWeightValues({
            weight: values.berat_badan,
            height: values.tinggi_badan
          }))

          dispatch(closeOverlayAndReset());
          dispatch(resetUserMeasurementDraft());
          
          delayedDispatch("instantSizeTeamDetail");
        }}/>);
      break;
      case "instantSizeTeamDetail":
        children.push(<OverlayViewInstantSizeTeamDetail
          selectedRegularSize={flowStateData.selectedRegularSize}
          height={flowStateData.height}
          weight={flowStateData.weight}
          productDetail={flowSelectionData.productDetail}
          />);
        break;
  }

  if (flowStateData.titleNumber != titleNumber) {
    flowStateCallback.setTitleNumber(titleNumber);
  }

  return children;
}