import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Grid,
} from "@mui/material";
import { socket } from "../socket";
import { useAppSelector } from "../reduxhooks";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import axios from "axios";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { ProductSpotlight } from "common-ts/dist/models/Cart";
import Countdown from "react-countdown";

interface LivestreamDashboardDialogProps {
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      "& .MuiDialog-paper": {
        width: "87vw",
        maxWidth: "1138px",
        height: "calc(87vw * 16 / 9)",
        maxHeight: "640px",
        margin: 0,
        borderRadius: 20,
        overflow: "hidden",
        padding: "0 0 0 0",
      },
    },
    headerContainer: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "5px",
    },
    streamIndicator: {
      width: 15,
      height: 15,
      borderRadius: "50%",
      marginRight: 10,
    },
    streamStatusText: {
      fontWeight: 500,
      fontSize: "10px",
      fontFamily: "OakesGrotesk",
      letterSpacing: 0.5,
      marginTop: "3px",
    },
    productContainer: {
      padding: 15,
      border: "1px solid #ccc",
      borderRadius: 8,
      marginBottom: 10,
    },
    form: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    formGroup: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "10px",
    },
    label: {
      minWidth: "120px",
    },
    input: {
      flex: 1,
      padding: "5px",
    },
    buttonGroup: {
      display: "flex",
      flexDirection: "row",
      gap: "10px",
      marginTop: "30px",
    },
    deleteButton: {
      padding: "5px 10px",
      color: "white",
      borderRadius: "4px",
      cursor: "pointer",
    },
    submitButton: {
      padding: "5px 10px",
      color: "white",
      borderRadius: "4px",
    },
    productText: {
      fontWeight: 500,
      color: "#121212",
      fontSize: "11px",
      marginBottom: "5px",
      fontFamily: "OakesGrotesk",
      letterSpacing: 0.5,
    },
    productTimestamp: {
      fontWeight: 300,
      color: "#121212",
      fontSize: "11px",
      marginBottom: "5px",
      fontFamily: "OakesGrotesk",
      letterSpacing: 0.5,
    },
    productPrice: {
      fontWeight: 400,
      color: "#121212",
      fontSize: "11px",
      fontFamily: "OakesGrotesk",
      letterSpacing: 0.5,
    },
  })
);

const schemaUpdate = yup.object().shape({
  discountedPrice: yup.number().required("Required"),
  timestampLimit: yup.string().required("Required"),
  isMainSpotlight: yup.boolean().required("Required"),
});

const schemaAdd = yup.object().shape({
  productId: yup.string().required("Required"),
  discountedPrice: yup.number().required("Required"),
  timestampLimit: yup.string().required("Required"),
  isMainSpotlight: yup.boolean().required("Required"),
});

const countdownRenderer = ({ hours, minutes, seconds, completed }: any) => {
  if (completed) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "7px 0px 5px 0px",
          padding: "4px 12px 1px 12px",
          borderRadius: "20px",
          color: "#ffffff",
          backgroundColor: "#121212",
          maxWidth: "100px",
        }}
      >
        <Typography
          style={{
            fontSize: "12px",
            fontFamily: "OakesGrotesk",
            fontWeight: 400,
          }}
        >
          Diskon Berakhir
        </Typography>
      </div>
    );
  } else {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "7px 0px 5px 0px",
          padding: "4px 12px 1px 12px",
          borderRadius: "20px",
          color: "#ffffff",
          backgroundColor: "#121212",
          maxWidth: "100px",
        }}
      >
        <Typography
          style={{
            fontSize: "12px",
            fontFamily: "OakesGrotesk",
            fontWeight: 400,
          }}
        >
          {String(hours).padStart(2, "0")}:{String(minutes).padStart(2, "0")}:
          {String(seconds).padStart(2, "0")}
        </Typography>
      </div>
    );
  }
};

const LivestreamDashboardDialog: React.FC<LivestreamDashboardDialogProps> = ({
  open,
  onClose,
}) => {
  const classes = useStyles();
  const productsDictionary = useAppSelector(
    (state) => state.productsList.productsDictionary
  );
  const [stageInfo, setStageInfo] = useState<{
    event: string;
    streamIsOn: boolean;
    productSpotlightList: ProductSpotlight[];
  }>({
    event: "",
    streamIsOn: false,
    productSpotlightList: [],
  });

  let availableProductId = objectKeys(productsDictionary);
  availableProductId = availableProductId.sort()

  const [selectedProduct, setSelectedProduct] = useState("");
  const [currentTime, setCurrentTime] = useState("");
  const [isAddingProduct, setIsAddingProduct] = useState(false);

  const [confirmationDialog, setConfirmationDialog] = useState<{
    open: boolean;
    action: string;
    data: ProductSpotlight | null;
  }>({
    open: false,
    action: "",
    data: null,
  });

  const showConfirmationDialog = (
    action: string,
    data: ProductSpotlight | null
  ) => {
    setConfirmationDialog({ open: true, action, data });
  };

  const handleConfirmAction = async () => {
    const { action, data } = confirmationDialog;
    let dataValues: any = {};
    dataValues.cacheKeyName = "livestreamCache";
    dataValues.content = { ...stageInfo };

    switch (action) {
      case "add":
        if (data) {
          dataValues.content.productSpotlightList = [
            ...stageInfo.productSpotlightList,
            data,
          ];
        }
        break;
      case "update":
        if (data) {
          dataValues.content.productSpotlightList = replaceProduct(
            stageInfo.productSpotlightList,
            data
          );
        }
        break;
      case "delete":
        if (data) {
          dataValues.content.productSpotlightList =
            stageInfo.productSpotlightList.filter(
              (item) => item.productId !== data.productId
            );
        }
        break;
      case "toggleStream":
        dataValues.content.streamIsOn = !stageInfo.streamIsOn;
        break;
    }

    console.log("To be sent: " + JSON.stringify(dataValues));

    const response = await axios({
      method: "post",
      url: "https://8mav2hp6ki.execute-api.ap-southeast-1.amazonaws.com/OrderAPIProduction/livestream-3820238",
      data: JSON.stringify(dataValues),
    });

    console.log("Response : " + JSON.stringify(response));

    // Await Axios call here to send updated dataValues

    setConfirmationDialog({ open: false, action: "", data: null });
    handleRefreshLivestream();
  };

  const handleRefreshLivestream = () => {
    socket.timeout(5000).emit("chat message", {
      chatUserId: "USER-54101",
      creationDateTime: new Date().toISOString(),
      contentType: "STRING",
      content: "L2310",
      author: "OTHER",
    });

    setCurrentTime(new Date().toISOString());
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios({
          method: "post",
          url: "https://ojm2bfbydd.execute-api.ap-southeast-1.amazonaws.com/UserAPIProd/video-token",
          data: JSON.stringify({
            getStageInfo: true,
            getStageToken: false,
            joinChatRoom: false,
          }),
        });

        setStageInfo(response.data.getStageInfo);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();

    setSelectedProduct("");
  }, [currentTime]);

  return (
    <Dialog open={open} onClose={onClose} className={classes.dialog}>
      <DialogTitle>
        <div>
          <div className={classes.headerContainer}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                className={classes.streamIndicator}
                style={{
                  backgroundColor: stageInfo.streamIsOn ? "red" : "#cccccc",
                }}
              />
              <Typography
                className={classes.streamStatusText}
                color={stageInfo.streamIsOn ? "error" : "text.secondary"}
              >
                {stageInfo.streamIsOn ? "ON AIR" : "OFF AIR"}
              </Typography>
            </div>
            <Button
              variant="contained"
              color="primary"
              onClick={handleRefreshLivestream}
            >
              Refresh
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              onClick={() => setIsAddingProduct(!isAddingProduct)}
            >
              {isAddingProduct ? "Cancel" : "Add Product"}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => showConfirmationDialog("toggleStream", null)}
            >
              TURN {stageInfo.streamIsOn ? "OFF" : "ON"}
            </Button>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        {!isAddingProduct ? (
          <Grid container>
            {stageInfo.productSpotlightList.map(
              (product: ProductSpotlight, index: number) => (
                <Grid item xs={12} key={product.productId}>
                  <div
                    className={classes.productContainer}
                    style={{
                      backgroundColor: product.isMainSpotlight
                        ? "#e8f5e9"
                        : "transparent",
                    }}
                    onClick={() => setSelectedProduct(product.productId)}
                  >
                    {selectedProduct === product.productId ? (
                      <Formik
                        initialValues={{
                          discountedPrice: product.discountedPrice,
                          timestampLimit: product.timestampLimit,
                          isMainSpotlight: product.isMainSpotlight,
                        }}
                        validationSchema={schemaUpdate}
                        onSubmit={(values) => {
                          const updatedItem: ProductSpotlight = {
                            isMainSpotlight: values.isMainSpotlight,
                            discountedPrice: values.discountedPrice
                              ? Number(values.discountedPrice)
                              : product.discountedPrice,
                            productId: product.productId,
                            timestampStart: values.timestampLimit
                              ? new Date(values.timestampLimit).toISOString()
                              : product.timestampLimit,
                            timestampLimit: values.timestampLimit
                              ? new Date(values.timestampLimit).toISOString()
                              : product.timestampLimit,
                          };

                          showConfirmationDialog("update", updatedItem);
                        }}
                      >
                        {({ values, isValid, setFieldValue }) => (
                          <Form className={classes.form}>
                            <Typography className={classes.productText}>
                              {product.productId}
                            </Typography>
                            <div className={classes.formGroup}>
                              <label
                                htmlFor="discountedPrice"
                                className={classes.label}
                              >
                                Discounted Price:
                              </label>
                              <input
                                type="text"
                                id="discountedPrice"
                                name="discountedPrice"
                                value={values.discountedPrice}
                                onChange={(e) =>
                                  setFieldValue(
                                    "discountedPrice",
                                    e.target.value
                                  )
                                }
                                className={classes.input}
                              />
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                gap: "10px",
                              }}
                            >
                              <label
                                htmlFor="timestampLimit"
                                style={{ minWidth: "120px" }}
                              >
                                Timestamp Limit:
                              </label>
                              <input
                                type="datetime-local"
                                id="timestampLimit"
                                name="timestampLimit"
                                value={values.timestampLimit}
                                onChange={(e) =>
                                  setFieldValue(
                                    "timestampLimit",
                                    e.target.value
                                  )
                                }
                                style={{ flex: 1, padding: "5px" }}
                              />
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                gap: "10px",
                              }}
                            >
                              <label style={{ minWidth: "120px" }}>
                                Is Main Spotlight:
                              </label>
                              <div style={{ display: "flex", gap: "10px" }}>
                                <label>
                                  <input
                                    type="radio"
                                    name="isMainSpotlight"
                                    value="true"
                                    checked={values.isMainSpotlight === true}
                                    onChange={() =>
                                      setFieldValue("isMainSpotlight", true)
                                    }
                                  />
                                  Yes
                                </label>
                                <label>
                                  <input
                                    type="radio"
                                    name="isMainSpotlight"
                                    value="false"
                                    checked={values.isMainSpotlight === false}
                                    onChange={() => 
                                      setFieldValue("isMainSpotlight", false)
                                    }
                                  />
                                  No
                                </label>
                              </div>
                            </div>

                            <div className={classes.buttonGroup}>
                              <Button
                                variant="contained"
                                onClick={() => {
                                  showConfirmationDialog("delete", {
                                    ...product,
                                  });
                                }}
                                className={classes.deleteButton}
                                style={{
                                  backgroundColor: "red",
                                  cursor: isValid ? "pointer" : "not-allowed",
                                }}
                              >
                                Delete Product
                              </Button>
                              <Button
                                type="submit"
                                variant="contained"
                                disabled={!isValid}
                                className={classes.submitButton}
                                style={{
                                  backgroundColor: isValid ? "#4CAF50" : "#ccc",
                                  cursor: isValid ? "pointer" : "not-allowed",
                                }}
                              >
                                Update Product
                              </Button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    ) : (
                      <div>
                        <Typography className={classes.productText}>
                          {product.productId}
                        </Typography>
                        <Typography className={classes.productTimestamp}>
                          {new Date(product.timestampLimit).toLocaleString()}
                        </Typography>
                        <Countdown
                          date={product.timestampLimit}
                          renderer={countdownRenderer}
                        />
                        <Typography className={classes.productPrice}>
                          Rp{product.discountedPrice.toLocaleString()}
                        </Typography>
                      </div>
                    )}
                  </div>
                </Grid>
              )
            )}
          </Grid>
        ) : (
          <Formik
            initialValues={{
              productId: "",
              discountedPrice: "",
              timestampLimit: "",
              isMainSpotlight: false,
            }}
            validationSchema={schemaAdd}
            onSubmit={(values) => {
              const newItem: ProductSpotlight = {
                isMainSpotlight: values.isMainSpotlight,
                discountedPrice: Number(values.discountedPrice),
                productId: values.productId,
                timestampStart: new Date().toISOString(),
                timestampLimit: new Date(values.timestampLimit).toISOString(),
              };

              showConfirmationDialog("add", newItem);
            }}
          >
            {({ values, isValid, setFieldValue }) => (
              <Form className={classes.form}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "10px",
                  }}
                >
                  <label htmlFor="productId" style={{ minWidth: "120px" }}>
                    Product ID:
                  </label>
                  <select
                    id="productId"
                    name="productId"
                    value={values.productId}
                    onChange={(e) => setFieldValue("productId", e.target.value)}
                    style={{ flex: 1, padding: "5px" }}
                  >
                    <option value="">Select productId</option>
                    {availableProductId.map((productId) => (
                      <option key={productId} value={productId}>
                        {productId}
                      </option>
                    ))}
                  </select>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "10px",
                  }}
                >
                  <label
                    htmlFor="discountedPrice"
                    style={{ minWidth: "120px" }}
                  >
                    Discounted Price:
                  </label>
                  <input
                    type="text"
                    id="discountedPrice"
                    name="discountedPrice"
                    value={values.discountedPrice}
                    onChange={(e) =>
                      setFieldValue("discountedPrice", e.target.value)
                    }
                    style={{ flex: 1, padding: "5px" }}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "10px",
                  }}
                >
                  <label htmlFor="timestampLimit" style={{ minWidth: "120px" }}>
                    Timestamp Limit:
                  </label>
                  <input
                    type="datetime-local"
                    id="timestampLimit"
                    name="timestampLimit"
                    value={values.timestampLimit}
                    onChange={(e) =>
                      setFieldValue("timestampLimit", e.target.value)
                    }
                    style={{ flex: 1, padding: "5px" }}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "10px",
                  }}
                >
                  <label style={{ minWidth: "120px" }}>
                    Is Main Spotlight:
                  </label>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <label>
                      <input
                        type="radio"
                        name="isMainSpotlight"
                        value="true"
                        checked={values.isMainSpotlight === true}
                        onChange={() => setFieldValue("isMainSpotlight", true)}
                      />
                      Yes
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="isMainSpotlight"
                        value="false"
                        checked={values.isMainSpotlight === false}
                        onChange={() => setFieldValue("isMainSpotlight", false)}
                      />
                      No
                    </label>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={!isValid}
                    style={{
                      padding: "5px 10px",
                      backgroundColor: isValid ? "#4CAF50" : "#ccc",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      cursor: isValid ? "pointer" : "not-allowed",
                    }}
                  >
                    Add Product
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Back
        </Button>
      </DialogActions>

      <Dialog
        open={confirmationDialog.open}
        onClose={() =>
          setConfirmationDialog({ open: false, action: "", data: null })
        }
      >
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <Typography>Action: {confirmationDialog.action}</Typography>
          {confirmationDialog.data && (
            <pre>{JSON.stringify(confirmationDialog.data, null, 2)}</pre>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              setConfirmationDialog({ open: false, action: "", data: null })
            }
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={handleConfirmAction} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default LivestreamDashboardDialog;

// Helper Function
function replaceProduct(
  list: ProductSpotlight[],
  newItem: ProductSpotlight
): ProductSpotlight[] {
  const index = list.findIndex((item) => item.productId === newItem.productId);
  if (index !== -1) {
    return [...list.slice(0, index), newItem, ...list.slice(index + 1)];
  }
  return list;
}

function objectKeys<T extends object>(obj: T): Array<keyof T> {
  return Object.keys(obj) as Array<keyof T>;
}
