import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useAppSelector, useAppDispatch } from '../reduxhooks';
import { Button, Box, Typography, CircularProgress, Grid, Radio } from '@material-ui/core';
import React, { SetStateAction } from 'react';
import { getNumberOfItems, getShipmentPriceSubsidyAmount, resetCourier, updateCourierInOrder } from '../redux/CartRedux';
import { ArrowBack } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import { APIRequestStatus } from 'common-ts/dist/models/APIRequestStatus';
import { sendWebsiteEventMessage } from '../telegrambotevents';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  circularProgressContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "250px",
  },
  addressSegmentBody: {
  },
  title: {
    padding: '20px 20px 16px 20px',
    fontWeight: theme.typography.fontWeightBold as number,
    fontSize: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
  },
  containerStyle: {
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '5px',
    paddingBottom: '10px',
  },
  nameText: {
    fontWeight: theme.typography.fontWeightBold as number,
    fontSize: '16px',
    marginBottom: '4px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
  bodyText: {
    fontWeight: theme.typography.fontWeightMedium as number,
    fontSize: '15px',
    marginLeft: '3px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
  buttonStyle: {
    marginTop: '5px',
  },
  chooseAddressButton: {
    marginTop: '12px',
  },
  courierContainer: {
    padding: "10px 17px 8px 17px",
    position: "relative",
  },
  courierContainerInner: {
    padding: "12px 3px 8px 3px",
    borderTop: '1px solid #cecece',
  },
  courierContainerText: {
    position: "relative"
  },
  barIconArrowBack: {
    padding: '18px 10px 0px 16px',
    margin: '0px',
    color: '#000',
  },
  courierLogo: {
    width: "40px",
    paddingRight: "10px"
  },
  serviceNameText: {
    fontSize: "20px",
    fontWeight: 600,
  },
  subTitleText: {
    paddingTop: "8px",
    fontSize: "13px",
    fontWeight: 500,
  },
  contentText: {
    marginTop: "2px",
    fontSize: "12px",
    fontWeight: 300,
  },
  serviceNameContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  rekomendasiTextSpacerBox: {
    width: "3px",
    height: "21px",
  },
  rekomendasiTextCont: {
    width: "fit-content",
    paddingTop: "3px",
    paddingBottom: "6px",
    paddingLeft: "1px",
  },
  rekomendasiText: {
    fontSize: "8px",
    backgroundColor: "green",
    fontWeight: 500,
    letterSpacing: "0.4px",
    borderRadius: "10px",
    color: "white",
    padding: "4px 8px 2px 8px"
  },
  dipilihTextCont: {
    width: "fit-content",
    paddingLeft: "8px",
    paddingTop: "2px",
    position: "absolute",
    top: "34px",
    right: "30px",
  },
  dipilihText: {
    fontSize: "13px",
    backgroundColor: "#0f1488",
    fontWeight: 500,
    letterSpacing: "0.4px",
    color: "white",
    padding: "4px 4px 2px 4px"
  },
  originalShipmentPriceText: {
    fontSize: "12px",
    color: "red",
    textDecoration: "line-through",
    marginRight: "12px",
  }
}),
);

const courierImagesDict: {[key:string]: string} = {
    "sicepat":  "https://files-far-east.s3.ap-southeast-1.amazonaws.com/general/logistik-sicepat.png",
    "jne":      "https://files-far-east.s3.ap-southeast-1.amazonaws.com/general/logistik-jne.png",
    "jnt":      "https://files-far-east.s3.ap-southeast-1.amazonaws.com/general/logistik-jnt-express.png",
    "sap":      "https://files-far-east.s3.ap-southeast-1.amazonaws.com/general/logistik-sap-express.png",
    "lion":     "https://files-far-east.s3.ap-southeast-1.amazonaws.com/general/logistik-lion.png",
    "idx":      "https://files-far-east.s3.ap-southeast-1.amazonaws.com/general/logistik-id-express.png",
    "anteraja": "https://files-far-east.s3.ap-southeast-1.amazonaws.com/general/logistik-anteraja.png",
    "sentral":  "https://files-far-east.s3.ap-southeast-1.amazonaws.com/general/logistik-sentral-kargo.jpeg",
    "ncs":      "https://files-far-east.s3.ap-southeast-1.amazonaws.com/general/logistik-ncs.png",
    "ninja":    "https://files-far-east.s3.ap-southeast-1.amazonaws.com/general/logistik-ninja.png",
    "tiki":     "https://files-far-east.s3.ap-southeast-1.amazonaws.com/general/logistik-tiki.png",
    "jtcargo":  "https://files-far-east.s3.ap-southeast-1.amazonaws.com/general/logistik-jnt.png",
}

interface PageCheckoutProps {
  checkoutStep: string,
  setCheckoutStep: React.Dispatch<SetStateAction<string>>,
  isDisabled: boolean,
}

export default function PageCheckoutCourier({ checkoutStep, setCheckoutStep, isDisabled } : PageCheckoutProps) {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const [selectedIndex, setSelectedIndex] = React.useState<number>(-1);

  const cart = useAppSelector((state) => state.cart);
  const courierOptions = useAppSelector(state => state.cart.courierOptions ? state.cart.courierOptions : []);
  const courierOptionsFetchStatusLoading = useAppSelector(state => state.cart.courierOptionsFetchStatus === APIRequestStatus.RequestInProgress);

  const currentShipmentPriceCut = getShipmentPriceSubsidyAmount(getNumberOfItems(cart));

  const filteredCourierOptions = ([...courierOptions])
  .sort((a: any, b: any) => Number(a.cost) - Number(b.cost))
  .filter((item) => item.etd && 
    item.etd !== "-" && 
    !(item.service_name?.toLowerCase()?.includes("same")) && // Filter out null entries and same day services 
    !(item.group?.includes("one_day")) && // Filter out null entries and same day services 
    !(item.service.includes("ncs")) && // Filter out NCS
    !(item.service.includes("tiki")) && // Filter out TIKI
    !(item.service.includes("jnt")) && // Filter out JNT
    // !(item.service.includes("lion")) && // Filter out Lion
    !(item.service.includes("sap"))); // Filter out SAP

  let orderedCourierOptions = filteredCourierOptions;

  // JNT Reordering - "Anything but JNT"
  if (orderedCourierOptions?.[0]?.service === "jnt") {
    let firstIndexWithoutJnt = -1;
    for (let i = 0; i < orderedCourierOptions.length; i++) {
      if (orderedCourierOptions?.[i]?.service && orderedCourierOptions[i].service !== 'jnt') {
        firstIndexWithoutJnt = i;
        break;
      }
    }
    if (firstIndexWithoutJnt !== -1) {
      let newOrderedCourierOptions: any[] = [];
      newOrderedCourierOptions.push(orderedCourierOptions[firstIndexWithoutJnt]);
      for (let i = 0; i < orderedCourierOptions.length; i++) {
        if (i !== firstIndexWithoutJnt) {
          newOrderedCourierOptions.push(orderedCourierOptions[i]);
        }
      }
      orderedCourierOptions = newOrderedCourierOptions;
    }
  }

  return (
    <Box className={classes.addressSegmentBody}>
      <IconButton
        className={classes.barIconArrowBack}
        color="primary"
        aria-label="open drawer"
        edge="start"
        onClick={() => {
          dispatch(resetCourier());
          setCheckoutStep("address");
        }}
      >
        <ArrowBack fontSize={"medium"}/>
        <Typography variant="h5" className={classes.title}>
          Pilih Kurir
        </Typography>
      </IconButton>
      <Box>
        {
          courierOptionsFetchStatusLoading ? 
          <Box className={classes.circularProgressContainer}>
            <CircularProgress/>
          </Box>:
          orderedCourierOptions.map((item: any, index: number) => {
            return (
            <Box className={classes.courierContainer} onClick={() => {
              dispatch(updateCourierInOrder({
                shipmentPrice: Number(item.cost),
                estimasiPengiriman: item.etd,
                courierSelectionObject: item,
                courierWeight: Math.max(1000, 250 * getNumberOfItems(cart)),
              }));
              setSelectedIndex(index);
              sendWebsiteEventMessage("[CHECKOUT] Courier " + item.service_name + " harga " + item.cost + " dipilih.");
            }}>
              <Grid container className={classes.courierContainerInner}>
                <Grid item xs={2}>
                  {
                    index === 0 ? 
                    <Box className={classes.rekomendasiTextSpacerBox}></Box>:
                    null
                  }
                  <Radio checked={index === selectedIndex} style={{ color: index === selectedIndex ? "#232323" : "#676767" }}/>
                </Grid>
                <Grid item xs={10} className={classes.courierContainerText} >
                  {
                    index === 0 ? 
                    <Box className={classes.rekomendasiTextCont}>
                      <Typography className={classes.rekomendasiText}>
                        REKOMENDASI
                      </Typography>
                    </Box>:
                    null
                  }
                  <Box className={classes.serviceNameContainer}>
                    <img 
                    src={courierImagesDict[item.service as string]} 
                    alt={"key-" + index}
                    className={classes.courierLogo}/>
                    <Typography className={classes.serviceNameText}>
                      {item.service_name} 
                    </Typography>
                  </Box>
                  <Grid container direction='row'>
                    <Grid item xs={6}>
                      <Typography className={classes.subTitleText}>
                        {"Estimasi Pengiriman"}
                      </Typography>
                      <Typography className={classes.contentText}>
                        {item.etd + " hari"}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className={classes.subTitleText}>
                        {"Price"}
                      </Typography>
                      <Typography className={classes.contentText}>
                        <span className={classes.originalShipmentPriceText}>{"Rp " + Number(item.cost).toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                        { 
                          currentShipmentPriceCut >= Number(item.cost) ? 
                          "FREE" : 
                          "Rp " + (Number(item.cost) - currentShipmentPriceCut).toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>)
          })
        }
      </Box>
    </Box>
  );
}
