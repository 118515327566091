import { Box, Button, Grid, IconButton, Typography } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import React, { useState } from 'react'
import { useAppSelector, useAppDispatch } from '../reduxhooks';
import { addItemCount, canAddProductAmountToCart, getDiscountedPriceInProductSpotlightList, getTimestampInProductSpotlightList, postCanAddItemCount, removeItem, removeItemCount } from '../redux/CartRedux';
import { CartStateItem, SizeType } from 'common-ts/dist/models/Cart';
import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';
import { ProductsList } from 'common-ts/dist/models/Product';
import Close from '@material-ui/icons/Close';
import { Mixpanel } from '../mixpanel';
import ReactHtmlParser from 'react-html-parser';
import hash from 'object-hash';
import { updateCurrentScreen } from '../redux/UIOverlayNavbarRedux';
import { useHistory } from 'react-router-dom';
import Countdown from 'react-countdown';
import EditIcon from '@material-ui/icons/Edit';
import { closeOverlayAndReset as closeOverlayAndResetEditView, openOverlay as openOverlayEditView, setValues as setValuesEditView } from '../redux/UIOverlayMeasurementEditViewRedux';
import { countdownRenderer } from './PageProductDetail';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cartContentItem: {
      width: '100%',
      padding: '0px',
      marginTop: '10px',
    },
    cartContentImageGridItem: {
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        justifyContent: 'center',
      }
    },
    cartContentImageContainer: {
      minWidth: '100px',
      width: '100px',
      // height: '100px',
      overflow: 'hidden',
      marginLeft: '2px',
      marginRight: '24px',
      [theme.breakpoints.down('xs')]: {
        minWidth: '90px',
        width: '90px',
        // height: '90px',
        marginLeft: '0px',
        marginRight: '0px',
      }
    },
    cartContentImage: {
      width: '100%',
    },
    cartItemDetails: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: 'auto',
      paddingLeft: '20px',
      [theme.breakpoints.down('xs')]: {
        paddingLeft: '16px',
      },
    },
    cartContentItemName: {
      display: 'block',
      textAlign: 'left',
      marginBottom: '10px',
    },
    cartContentItemNameText: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('xs')]: {
        fontSize: '14px',
      }
    },
    cartContentItemSub: {
      display: 'block',
      textAlign: 'left',
      marginBottom: '14px',
    },
    cartContentItemSubText: {
      // textOverflow: 'ellipsis',
      // overflow: 'hidden',
      // whiteSpace: 'nowrap',
      fontSize: '14px',
      color: '#3e3e3e',
      [theme.breakpoints.down('xs')]: {
        fontSize: '12px',
      }
    },
    cartContentItemSubTextRed: {
      fontSize: '14px',
      color: 'red',
      [theme.breakpoints.down('xs')]: {
        fontSize: '12px',
      }
    },
    cartContentItemSubGantiUkuranText: {
      cursor: "pointer",
      fontSize: "10px",
      textWrap: "nowrap",
      fontWeight: 500,
      textDecoration: "underline #121212 solid 1px",
      textUnderlineOffset: "3px",
      paddingBottom: "2px",
    },
    cartContentItemAmount: {
      display: 'inline-block',
      width: '30px',
    },
    cartContentItemAmountText: {
      fontSize: '13px',
      fontFamily: 'Open Sans, sans-serif',
    },
    cartItemAmountButtonsContainer: {
      width: '90px',
      border: '2px solid rgb(211, 212, 213)',
      marginBottom: '6px',
    },
    cartItemAmountButton: {
      width: '30px',
      height: '30px',
    },
    removeAndPriceContainer: {
      flexDirection: 'column',
      justifyContent: 'space-between',
      display: 'flex',
      alignItems: 'flex-end',
    },
    removeItemButton: {
    },
    itemPrice: {
      fontFamily: 'Open Sans, sans-serif',
      fontSize: '15px',
      paddingBottom: '6px',
      textAlign: 'right',
      [theme.breakpoints.down('xs')]: {
        fontSize: '12px',
      }
    },
    itemStrikePrice: {
      fontFamily: 'Open Sans, sans-serif',
      fontSize: '12px',
      paddingBottom: '6px',
      // marginRight: '6px',
      textDecoration: 'line-through',
      [theme.breakpoints.down('xs')]: {
        fontSize: '10px',
      }
    },
    sizeOptionsBoxDetailText: {
      fontWeight: 500,
      letterSpacing: 0.5,
      paddingTop: '2px',
      fontSize: '2.1vw', // 9px -> 3px is 0.8vw
      [theme.breakpoints.up('sm')]: {
        fontSize: '9px',
        paddingTop: '2px',
      }
    },
    sizeOptionsBoxDetailIconButton: {
      border: '1px solid #232323',
      borderRadius: '30px',
      backgroundColor: '#232323',
      color: '#fefefe',
      padding: '1.2vw 2.4vw',
      [theme.breakpoints.up('sm')]: {
        padding: '5px 10px 5px 10px',
      }
    },
    sizeOptionsBoxEditIconButton: {
      border: '1px solid #232323',
      borderRadius: '40px',
      backgroundColor: '#232323',
      color: '#fefefe',
      padding: '1.5vw 1.5vw',
      [theme.breakpoints.up('sm')]: {
        padding: '5px 7px 5px 7px',
      }
    },
  }),
);

export interface CartContentItemProps {
  cartItem: CartStateItem,
  productsList: ProductsList,
  isModifiable: boolean,
  parentRenderCallback: (renderTime: string) => void,
  hideDiscountedPrice?: boolean
  showDetailEditButton?: boolean
}

function CartContentItem({cartItem, productsList, isModifiable, parentRenderCallback, hideDiscountedPrice, showDetailEditButton}: CartContentItemProps) {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const history = useHistory();

  const productDetail = productsList.productsDictionary[cartItem.productId];
  const userBodyMeasurementsDict = useAppSelector(state => state.userMeasurementsList.userBodyMeasurementsDictionary);
  const userClothingMeasurementsDict = useAppSelector(state => state.userMeasurementsList.userClothingMeasurementsDictionary);
  const userAccount = useAppSelector(state => state.account);
  const cart = useAppSelector(state => state.cart);

  const key2 = productDetail?.clothingType + "-" + cartItem.fitType;

  if (productDetail === undefined) {
    return null;
  }

  let measurementOwnedByUser = false;
  if (cartItem.sizeType === 'regular' || cartItem.measurementIsBodyMeasurement) {
    measurementOwnedByUser = true;
  } else if ( cartItem.sizeType === 'personal'
    && userClothingMeasurementsDict?.[key2]?.[cartItem.measurementCreationDateTime]?.[0]) {
    let cartItemMeasurement = userClothingMeasurementsDict[key2][cartItem.measurementCreationDateTime][0];
    measurementOwnedByUser = (cartItemMeasurement.idType === 'email'
    && cartItemMeasurement.email === userAccount.email)
    || (cartItemMeasurement.idType === 'phoneNumber'
    && cartItemMeasurement.phoneNumber === userAccount.phoneNumber);
  }

  // We set everything as ok for this current iteration
  measurementOwnedByUser = true;

  let sizeText = "Size: ";
  // if (measurementOwnedByUser === false) {
  //   sizeText = "Measurement is not owned by user. Please add to cart with user's measurement";
  // } else {
  if (cartItem.sizeType === 'personal') {
    try {
      if (cartItem.measurementIsBodyMeasurement) {
        sizeText = sizeText + userBodyMeasurementsDict[cartItem.measurementCreationDateTime][0]?.measurementName;
      } else {
        sizeText = sizeText + userClothingMeasurementsDict[key2][cartItem.measurementCreationDateTime][0]?.measurementName;
      }
    } catch (error) {
      if ((cartItem as any)?.userMeasurementsForItem?.measurementName) {
        sizeText = sizeText + (cartItem as any).userMeasurementsForItem.measurementName;
      } else {
        sizeText = sizeText + "Loading..";
      }
    }
  } else {
    sizeText = sizeText + cartItem.regularSize;
  }
  // }

  var addItemInput = {
    productId: cartItem.productId,
    productDetail: productDetail,
    fitType: cartItem.fitType,
    sizeType: cartItem.sizeType,
    measurementCreationDateTime: cartItem.measurementCreationDateTime,
    measurementIsBodyMeasurement: cartItem.measurementIsBodyMeasurement,
    regularSize: cartItem.regularSize,
  }

  var cartStateItem: CartStateItem = { 
    productId: cartItem.productId,
    amount: 1,
    clothingType: productDetail.clothingType,
    fitType: cartItem.fitType,
    sizeType: cartItem.sizeType, // "personal" or "regular"
    measurementCreationDateTime: cartItem.measurementCreationDateTime,
    measurementIsBodyMeasurement: cartItem.measurementIsBodyMeasurement,
    regularSize: cartItem.regularSize,
  }

  // Check out if we can add item count
  var hashCartStateItem = hash(cartStateItem);

  if (cart.canAddItemDict[hashCartStateItem] === undefined) {
    dispatch(postCanAddItemCount(cartStateItem));
  }

  // Equals to -1 if not exist
  let defaultDiscPrice = productDetail.discountedPrice === undefined ? -1 : productDetail.discountedPrice;
  let spotlightDiscPrice = getDiscountedPriceInProductSpotlightList(cart.productSpotlightList, cartItem.productId);
  let thereIsDiscountedPrice = defaultDiscPrice !== -1 || spotlightDiscPrice !== -1;
  let discountedPrice = spotlightDiscPrice !== -1 ? spotlightDiscPrice : defaultDiscPrice;

  let timestampSpotlight = getTimestampInProductSpotlightList(cart.productSpotlightList, cartItem.productId);
  

  return (
    <Grid container justifyContent="space-between" className={classes.cartContentItem}>
      {/* Gambar */}
      <Grid item xs={4} sm={3} className={classes.cartContentImageGridItem}>
        <div className={classes.cartContentImageContainer}>
          <img className={classes.cartContentImage} src={productDetail.displayImage} alt="product img"/>
        </div>
      </Grid>
      {/* Konten */}
      <Grid container item xs={8}>
        <Grid container item xs={12}>
          <Grid item xs={isModifiable ? 8 : 12}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: !isModifiable ? '100%' : undefined
            }}
          >
            <div className={classes.cartItemDetails}>
              <div className={classes.cartContentItemName}>
                <Typography className={classes.cartContentItemNameText}>
                  <b>{ReactHtmlParser(productDetail.name)}</b>
                </Typography>
              </div>
              
              <div className={classes.cartContentItemSub}>
                {
                  measurementOwnedByUser ?
                  <Typography className={classes.cartContentItemSubText}>
                    {sizeText}
                  </Typography> :
                  <Typography className={classes.cartContentItemSubTextRed}>
                    {sizeText}
                  </Typography>
                }
                {
                showDetailEditButton ? (
                  <div
                  style={{
                    display: 'flex',
                    marginTop: '5px',
                    gap: '5px'
                  }}
                >
                  <IconButton
                    className={classes.sizeOptionsBoxDetailIconButton}
                    disabled={!(cartItem as any)['userMeasurementsForItem']}
                    onClick={() => {
                      dispatch(closeOverlayAndResetEditView());
                      dispatch(setValuesEditView({
                        overlayType: 'view',
                        clothingType: cartItem.clothingType,
                        fitType: cartItem.fitType,
                        isFullScreen: false,
                        currentMeasurementObject: (cartItem as any)['userMeasurementsForItem'],
                        productDetail: productDetail ?? undefined,
                        origin: 'order'
                      }))
                      dispatch(openOverlayEditView());
                    }}aria-label="zoom-out">
                      <Typography className={classes.sizeOptionsBoxDetailText}>
                        DETAIL SIZE ⓘ
                      </Typography>
                  </IconButton>
              
                  <IconButton
                    className={classes.sizeOptionsBoxEditIconButton}
                    disabled={!(cartItem as any)['userMeasurementsForItem']}
                    onClick={() => {
                      dispatch(closeOverlayAndResetEditView());
                      dispatch(setValuesEditView({
                        overlayType: 'edit',
                        clothingType: cartItem.clothingType,
                        fitType: cartItem.fitType,
                        isFullScreen: false,
                        currentMeasurementObject: (cartItem as any)['userMeasurementsForItem'],
                        productDetail: productDetail ?? undefined,
                        origin: 'order'
                      }))
                      dispatch(openOverlayEditView());
                    }} aria-label="zoom-out">
                      <EditIcon style={{height: '12px', width: '12px'}}/>
                  </IconButton>
                </div>
                ) : (
                  null
                )
              }
              </div>
              {
                isModifiable ? 
                <div className={classes.cartContentItemSub}>
                  <Typography className={classes.cartContentItemSubGantiUkuranText} onClick={()=> {
                    dispatch(updateCurrentScreen("DEFAULT")); 
                    history.push("/shop/product/" + cartItem.productId);
                  }}>
                    PILIH UKURAN LAIN
                  </Typography>
                </div>:
                null
              }
              {
                isModifiable ? (
                  <div className={classes.cartItemAmountButtonsContainer}>
                    <IconButton
                      color="primary"
                      aria-label="Decrement item count"
                      onClick={() => {
                        dispatch(removeItemCount({
                          productId: cartItem.productId,
                          fitType: cartItem.fitType,
                          sizeType: cartItem.sizeType,
                          measurementCreationDateTime: cartItem.measurementCreationDateTime,
                          regularSize: cartItem.regularSize,
                        }));
                        (window as any).dataLayer.push({'event': 'cartRemoveItemCount', 'productId': "productId-" + cartItem.productId});
                        Mixpanel.track('cartRemoveItemCount', {
                          'productId': "productId-" + cartItem.productId,
                        });
                      }}
                      disabled={!isModifiable || cartItem.amount < 2 || !measurementOwnedByUser}
                      className={classes.cartItemAmountButton}
                    >
                      <Remove fontSize="small" />
                    </IconButton>
                    <div className={classes.cartContentItemAmount}>
                      <Typography className={classes.cartContentItemAmountText}>
                        {cartItem.amount}
                      </Typography>
                    </div>
                    <IconButton
                      aria-label="Increment item count"
                      color="primary"
                      onClick={() => {dispatch(addItemCount(addItemInput));
                      (window as any).dataLayer.push({'event': 'cartAddItemCount', 'productId': "productId-" + cartItem.productId});
                      Mixpanel.track('cartAddItemCount', {
                        'productId': "productId-" + cartItem.productId,
                      });
                    }}
                      disabled={canAddProductAmountToCart(cart, cartStateItem) !== "ENOUGH" || !isModifiable || !measurementOwnedByUser}
                      className={classes.cartItemAmountButton}
                    >
                      <Add fontSize="small" />
                    </IconButton>
                  </div>
                ) :
                (
                  null
                )
              }
            </div>
          </div>
          </Grid>
          {
            isModifiable ? (
              <Grid item xs={4} 
                className={classes.removeAndPriceContainer}
              >
                <IconButton
                  edge="end"
                  aria-label="Remove item"
                  color="primary"
                  onClick={() => {dispatch(removeItem({ 
                      productId: cartItem.productId,
                      fitType: cartItem.fitType,
                      sizeType: cartItem.sizeType,
                      measurementCreationDateTime: cartItem.measurementCreationDateTime,
                      regularSize: cartItem.regularSize,
                    }));
                    (window as any).dataLayer.push({'event': 'cartRemoveItem', 'productId': "productId-" + cartItem.productId});
                    Mixpanel.track('cartRemoveItem', {
                      'productId': "productId-" + cartItem.productId,
                    });
                  }}
                  disabled={!isModifiable}
                  className={classes.removeItemButton}
                  size="small"
                >
                  <Close fontSize="small" />
                </IconButton>
                <div>
                  <Typography className={classes.itemPrice} >
                    { 
                      !thereIsDiscountedPrice || hideDiscountedPrice ?
                      null :
                      <span className={classes.itemStrikePrice}>
                        { "Rp " + productDetail.originalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        <br></br>
                      </span>
                    }
                    { 
                      !thereIsDiscountedPrice ? 
                      "Rp " + productDetail.originalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):
                      "Rp " + discountedPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") 
                    }
                  </Typography>
                  {
                    (new Date().toISOString() < timestampSpotlight) &&
                    <Box style={{margin: '2px 0px 7px'}}>
                      <Countdown
                        date={timestampSpotlight}
                        renderer={countdownRenderer}
                        onComplete={(timeDelta, completedOnStart) => {
                          setTimeout(() => {
                            parentRenderCallback(new Date().toISOString());
                            console.log('Rerender harusnya jalan')
                          }, 400)
                        }}
                      />
                    </Box>
                  } 
                </div>
              </Grid>
            ) : (
              null
            )
          }
        </Grid>
        <Grid item xs={12}>
        {
          !isModifiable ? (
            <div
            className={classes.cartItemDetails}
            style={{
              flexDirection: 'row',
              alignItems: 'flex-end',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
                gap: '7.5px',
                padding: '6px 10px 2.5px 10px',
                border: 'solid',
                borderWidth: '1px',
                borderColor: '#D5D6D7',
                borderRadius: 20,
              }}
            >
              <Typography 
                className={classes.itemPrice}
                style={{fontWeight: 500, fontFamily: 'OakesGrotesk', paddingBottom: 0, letterSpacing: '1px'}}
              >
                QTY
              </Typography>
              <Typography 
                className={classes.itemPrice}
                style={{fontWeight: 500, fontFamily: 'OakesGrotesk', paddingBottom: 0, letterSpacing: '1px'}}
              >
                {cartItem.amount}
              </Typography>
            </div>
            <div>
              <Typography className={classes.itemPrice} >
                { 
                  !thereIsDiscountedPrice || hideDiscountedPrice ? 
                  null :
                  <span className={classes.itemStrikePrice}>
                    { "Rp " + productDetail.originalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    <br></br>
                  </span>
                }
                { 
                  !thereIsDiscountedPrice ? 
                  "Rp " + productDetail.originalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):
                  "Rp " + discountedPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") 
                }
              </Typography>
              {
                (new Date().toISOString() < timestampSpotlight) &&
                <Box style={{margin: '2px 0px 7px'}}>
                  <Countdown
                    date={timestampSpotlight}
                    renderer={countdownRenderer}
                    onComplete={(timeDelta, completedOnStart) => {
                      setTimeout(() => {
                        parentRenderCallback(new Date().toISOString());
                      }, 400)
                    }}
                  />
                </Box>
              } 
            </div>
          </div>
          ) : (
            null
          )
        }
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CartContentItem;