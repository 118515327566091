import { Box, Button, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import { Address } from 'common-ts/dist/models/Address';
import { inputAddressForm, resetAddressDraft } from '../redux/AddressRedux';
import { getShipmentPrice, updateAddressInOrder } from '../redux/CartRedux';
import { closeOverlayAndReset, updateCurrentScreen } from '../redux/UIOverlayAddressViewRedux';
import { useAppDispatch, useAppSelector } from '../reduxhooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      padding: '20px',
      fontWeight: theme.typography.fontWeightBold as number,
    },
    addressContainer: {
      paddingLeft: '20px',
      paddingRight: '20px',
      paddingTop: '10px',
      paddingBottom: '10px',
    },
    addressNameText: {
      fontWeight: theme.typography.fontWeightBold as number,
      fontSize: '16px',
      marginBottom: '4px',
    },
    addressBodyText: {
      fontWeight: theme.typography.fontWeightMedium as number,
      fontSize: '15px',
      marginLeft: '3px',
    },
    buttonStyle: {
      marginTop: '10px',
      marginRight: '12px',
    }
  }),
);

interface Props {

}

export default function OverlayViewAddressListPanel() {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const addressList = useAppSelector(state => state.addressList.addressList);
  const userCurrentAccount = useAppSelector(state => state.account);

  let children: JSX.Element[] = [];
  for (let item of addressList) {
    children.push(
      <Box className={classes.addressContainer}>
        <Box>
          <Typography className={classes.addressNameText}>
            {item.addressName ? item.addressName : "Address"}
          </Typography>
          <Typography className={classes.addressBodyText}>
            {item.address}
          </Typography>
          <Typography className={classes.addressBodyText}>
            {item.kotaKecamatanText} {item.kodePos}
          </Typography>
          {
            !item.destinationKiriminAjaObject || !item.kecamatan ? 
            <Typography className={classes.addressBodyText} 
            style={{color: "#fa801c", fontSize: "12px", padding: "7px 0px 6px 0px"}}>
              Alamat Ini Butuh Dilengkapi Kecamatan.<br></br>Klik UBAH Untuk Melengkapi Kecamatan Alamat Ini.
            </Typography>:
            null
          }
        </Box>
        <Button onClick={() => {
          dispatch(resetAddressDraft());
          dispatch(inputAddressForm(item));
          
          dispatch(updateCurrentScreen("editAndChoose"));
        }}
          className={classes.buttonStyle}
          variant="outlined"
          startIcon={<EditIcon />}>
          <b>UBAH</b> 
        </Button>
        <Button 
          className={classes.buttonStyle}
          variant="outlined"
          disabled={!item.destinationKiriminAjaObject || !item.kecamatan}
          onClick={() => {
          dispatch(resetAddressDraft());
          dispatch(inputAddressForm(item));

          dispatch(closeOverlayAndReset());

          dispatch(updateAddressInOrder({
            addressName: item.addressName,
            address: item.address,
            provinsi: item.provinsi,
            kabupaten: item.kabupaten,
            kecamatan: item.kecamatan,
            kodePos: item.kodePos,
            kotaKecamatanText: item.kotaKecamatanText,
            keterangan: item.keterangan,
            phoneNumber: item.phoneNumber,
            destinationObject: item.destinationObject,
            destinationKiriminAjaObject: item.destinationKiriminAjaObject,
          }));
          // dispatch(getShipmentPrice(item.destinationObject));
        }}>
          <b>PILIH ALAMAT INI</b>
        </Button>
      </Box>
    );
  }
  children.push(
    <Box className={classes.addressContainer}>
      <Button onClick={() => {
        dispatch(resetAddressDraft());
        const creationDateTime = new Date().toISOString();
        let address: Address = {
          creationDateTime: creationDateTime,
          addressName: "Alamat " + ((addressList?.length ?? 0) + 1),
        };
        // Also set the phone number automatically when creating a new address
        if (userCurrentAccount?.phoneNumber) {
          address.phoneNumber = userCurrentAccount.phoneNumber;
        }
        dispatch(inputAddressForm(address));
        dispatch(updateCurrentScreen("editAndChoose"));
      }}
      className={classes.buttonStyle}
      variant="outlined"
      startIcon={<AddIcon />}>
        <b>BUAT ALAMAT BARU</b>
      </Button>
    </Box>
  );

  return (
    <Box>
      {children}
    </Box>
  )
}