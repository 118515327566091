import { Box, ButtonBase, CircularProgress, Divider, Fade, Grid, Link, Slide, useMediaQuery, useScrollTrigger, useTheme } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { ArrowBack } from '@material-ui/icons';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, Route, Switch, useHistory } from "react-router-dom";
import { Mixpanel } from '../mixpanel';
import { categoryMap } from 'common-ts/dist/models/CategoryList';
import { ProductCategory } from 'common-ts/dist/models/Product';
import { getNumberOfItems } from '../redux/CartRedux';
import { updateCurrentScreen } from '../redux/UIOverlayNavbarRedux';
import { useAppDispatch, useAppSelector } from '../reduxhooks';
import CartWindow from './CartWindow';
import MobileCategoryMenu from './MobileCategoryMenu';
import { BannerOngkir } from '../App'

interface stylesProps {
  textColor: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    title: {
      display: 'block',
      margin: 'auto',
    },
    titleLogoContainer: {
      height: '38px',
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        height: '34px',
      }
    },
    titleLogoButtonBase: {
      justifyContent: 'flex-start', // This is required for safari and ios
    },
    titleLogo: {
      height: '100%',
      display: 'block', // We use block so that the img is unique horizontally in the div
      filter: 'invert(0%) sepia(0%) saturate(7477%) hue-rotate(83deg) brightness(93%) contrast(106%)',
      // This is equivalent to #000.
      // [theme.breakpoints.down('xs')]: {
      //   height: '30%',
      // }
    },
    sectionDesktopAndMobile: {
      display: 'flex',
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    shopButton: {
      marginLeft: '24px',
    },
    buttonLinkContainer: {
      display: 'flex',
      paddingRight: '20px',
      [theme.breakpoints.down('xs')]: {
        paddingRight: '6px',
      },
      alignItems: 'center',
      '-webkit-tap-highlight-color': 'transparent',
    },
    buttonLink: {
      margin: 'auto',
      color: '#000',
      fontSize: '14px',
      textDecoration: 'none !important',
    },
    barIcon: {
      color: '#000',
      [theme.breakpoints.down('xs')]: {
        marginLeft: '4px',
        marginRight: '2px',
        padding: '4px'
      }
    },
    barIconArrowBack: {
      color: '#000',
      marginRight: '12px',
      [theme.breakpoints.down('xs')]: {
        padding: '4px',
        marginRight: '8px',
      }
    },
    appBar: {
      // paddingLeft: '20px',
      // paddingRight: '20px',
      // [theme.breakpoints.down('xs')]: {
      //   paddingLeft: '6px',
      //   paddingRight: '6px',
      // },
    },
    internalToolbarGrid: {
      paddingLeft: '20px',
      paddingRight: '20px',
      [theme.breakpoints.down('xs')]: {
        paddingLeft: '6px',
        paddingRight: '6px',
      },
    },
    productNavigation: {
      position: 'relative',
      visibility: 'visible',
      // top: '64px',
      height: '60px',
      [theme.breakpoints.down('xs')]: {
        // top: '56px',
        height: '40px',
      },
      left: '0px',
      backgroundColor: 'white',
      width: '100%',
      borderTop: '1px solid #efefef',
      borderBottom: '1px solid #efefef',
      display: 'flex',
      alignItems: 'center',
      overflowX: 'auto',
      overflowY: 'hidden',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    productNavigationContainer:{
      height: '100%',
      textDecoration: 'none',
      display: "flex",
      alignItems: "center",
      WebkitTapHighlightColor: 'rgba(255,255,255,0)',
      '&:hover': {
        textDecoration: 'none',
      }
    },
    productNavigationLeftSpacer: {
      minWidth: '20px',
      height: '10px',
      [theme.breakpoints.down('xs')]: {
        minWidth: '16px',
      },
    },
    productNavigationLinkText: {
      fontFamily: 'OakesGrotesk',
      margin: '3px 0px 0px 0px',
      fontSize: '10.5px',
      whiteSpace: 'nowrap',
      letterSpacing: '1.1px',
      fontWeight: 400,
      display: 'flex',
      height: '100%',
      alignItems: 'center',
    },
    productNavigationLinkTextDisabled: {
      fontFamily: 'OakesGrotesk',
      margin: '3px 0px 0px 0px',
      fontSize: '10.5px',
      whiteSpace: 'nowrap',
      letterSpacing: '1.1px',
      fontWeight: 400,
      display: 'flex',
      height: '100%',
      alignItems: 'center',
      color:'#aaaaaa',
    },
    productNavigationLinkTextSelected: {
      fontFamily: 'OakesGrotesk',
      margin: '3px 0px 0px 0px',
      fontSize: '10.5px',
      whiteSpace: 'nowrap',
      letterSpacing: '1.1px',
      fontWeight: 400,
      display: 'flex',
      height: '100%',
      alignItems: 'center',
      color: "#ffffff",
    },
    productNavigationLinkTextContainer: {
      padding: "0 10px",
      margin: "0 2px",
    },
    productNavigationLinkTextContainerSelected: {
      padding: "0 10px",
      margin: "0 2px",
      backgroundColor: "#2A2A2A",
      borderRadius: "14px",
    },
    leftContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '100%',
    },
    shopText: {
      fontFamily: 'OakesGrotesk',
      fontWeight: 600,
      fontSize: '11px',
      letterSpacing: "0.8px",
      margin: '1px 3px 0px 0px',
      [theme.breakpoints.only('xs')]: {
        margin: '0px 8px 0px 0px',
      },
      border: '0.9px solid #b0b0b0',
      borderRadius: '50px',
      padding: '4.3px 9px 1.1px 9.5px',
      textDecoration: 'none !important',
    }
  }),
);

const ScrollHandlerAppBar = (props:any) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 20,
  });

  // const triggerMove = useScrollTrigger({ target: window ? window : undefined, threshold: 40 });
  const triggerMove = false;

  const colorAtTop = props.transparentAtTop ? "transparent" : "#ffffff";
  const elevationAtTop = props.transparentAtTop ? 0 : 0;

  const transparency = props.transparent ? "" : "#FFFFFF";

  return (
    <Slide appear={false} direction="down" in={!triggerMove}>
      {React.cloneElement(props.children, {
        color: trigger ? "default" : colorAtTop,
        elevation: trigger ? 0 : elevationAtTop,
        style: {
          transition: trigger ? "0.3s" : "0.5s",
          background: trigger ? "#FFFFFF" : "",
        }
      })}
    </Slide>
  )
};

const ScrollHandlerBarElementColor = (props:any) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 20,
  });

  const topColor = props.coloredAtTop ? "#fff" : "#000";

  return React.cloneElement(props.children, {
    style: {
      color: trigger ? "#000" : topColor,
    }
  });
};

const ScrollHandlerLogo = (props:any) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  const hexfff = "invert(100%) sepia(0%) saturate(0%) hue-rotate(75deg) brightness(105%) contrast(103%)";
  const hex000 = "invert(0%) sepia(0%) saturate(7477%) hue-rotate(83deg) brightness(100%) contrast(106%)";

  const hexf3e45ckuning = "invert(81%) sepia(58%) saturate(418%) hue-rotate(4deg) brightness(100%) contrast(98%)";

  const topColor = props.coloredAtTop ? hexfff : hex000;
  let topSize;
  let topMargin;
  let defaultSize = "60%";
  if (props.largerTitleAtTop) {
    if (props.isSmOrUp) {
      topSize = "107%";
      topMargin = "14px";
      defaultSize = "77%";
    } else {
      topSize = "110%";
      topMargin = "10px";
      defaultSize = "80%";
    }
  } else {
    if (props.isSmOrUp) {
      topSize = "77%";
      topMargin = "";
      defaultSize = topSize;
    } else {
      topSize = "77%";
      topMargin = "";
      defaultSize = topSize;
    }
  }

  return React.cloneElement(props.children, {
    style: {
      filter: trigger ? hex000 : topColor,
      transition: trigger ? "0.24s" : "0.24s",
      height: trigger ? defaultSize : topSize,
      marginTop: trigger ? "" : topMargin,
    }
  });
}

export function LoadingCategoryFullscreenOverlay({displayLoadingOverlay}: {displayLoadingOverlay: boolean}) {
  const theme = useTheme();
  const isSmOrUp = useMediaQuery(theme.breakpoints.up("sm"));

  const [loadingImageIndex, setLoadingImageIndex] = useState<number>(0);
  
  useEffect(() => {
    if (displayLoadingOverlay) {
      setLoadingImageIndex((loadingImageIndex + 1) % 4);
    }
  }, [displayLoadingOverlay]);

  return (
    <Fade in={displayLoadingOverlay} timeout={300}>
      <div style={{
        position: 'fixed',
        top: '0px',
        left: '0px',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgb(10, 10, 10, 0.72)',
      }}>
        <div style={{
          width: isSmOrUp ? '30vw' : '80vw',
          height: 'fit-content',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          maxWidth: '1080px',
          alignItems: 'center',
          backgroundColor: 'rgb(0, 0, 0, 1.0)',
          borderRadius: '20px',
          position: 'relative',
        }}>
          <img
            alt={'loading-'}
            style={{
              width: '100%',
              borderRadius: '20px',
              aspectRatio: 0.8,
              visibility: displayLoadingOverlay ? 'visible' : 'hidden'
            }}
            src={displayLoadingOverlay ? 
              'https://files-far-east.s3.ap-southeast-1.amazonaws.com/general/loading-web-' + ((loadingImageIndex % 4) + 1) + '.jpg' : 
              'https://files-far-east.s3.ap-southeast-1.amazonaws.com/general/loading-web-' + (((loadingImageIndex + 1) % 4) + 1) + '.jpg' }
          />
          <div style={{
            position: 'absolute',
            bottom: '-34px',
            width: '120px',
            height: '13px',
            backgroundColor: '#ffffff',
            padding: '7px 7px 9px',
            borderRadius: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <CircularProgress size={10}/>
            <Typography style={{fontSize: '8px', marginLeft: '9px', paddingTop: '3px'}}>
              Membuka kategori...
            </Typography>
          </div>
        </div>
      </div>
    </Fade>);
}

interface Props {
  transparentAtTop: boolean
  coloredAtTop: boolean
  largerTitleAtTop: boolean
  transparent: boolean
  goBackFunction?: () => void
}

export default function NavBar({transparentAtTop, coloredAtTop, largerTitleAtTop, transparent, goBackFunction}: Props) {
  const dispatch = useAppDispatch();
  const numberOfItemsInCart = useAppSelector(state => getNumberOfItems(state.cart));
  const isLoggedIn = useAppSelector(state => state.account.authState === 'signedin');
  const [displayLoadingOverlay, setDisplayLoadingOverlay] = useState(false);
  const classes = useStyles();
  const navbarOverlayScreen = useAppSelector(state => state.overlayNavbar.currentScreen)
  const theme = useTheme();
  const isSmOrUp = useMediaQuery(theme.breakpoints.up("sm"));
  const history = useHistory();

  const handleClickCategory = () => {
    setDisplayLoadingOverlay(true);
    setTimeout(() => {
      setDisplayLoadingOverlay(false);
    }, 2450); 
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 350); 
  }

  return (
    <div className={classes.grow}>
      <ScrollHandlerAppBar transparentAtTop={transparentAtTop} transparent={transparent}>
        <AppBar position="fixed" color="transparent" className={classes.appBar} elevation={0}>
          <BannerOngkir />
          <Toolbar>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              wrap="nowrap"
              className={classes.internalToolbarGrid}
            >
              <Grid item xs={6} md={4} className={classes.sectionDesktopAndMobile}>
                <Box className={classes.leftContainer}>
                  <Switch>
                    <Route path="/shop/product/:productId">
                      <ScrollHandlerBarElementColor coloredAtTop={coloredAtTop} >
                        <IconButton
                          className={classes.barIconArrowBack}
                          color="primary"
                          aria-label="open drawer"
                          edge="start"
                          onClick={() => {
                            Mixpanel.track('navigateRoute', {
                              'routePath': '/shop',
                              'routeSource': 'navBarProductDetailBack',
                            });
                            Mixpanel.track('goToShopPage', {
                              'routeSource': 'navBarProductDetailBack'
                            })
                            if (goBackFunction) {
                              goBackFunction();
                            } else {
                              history.goBack();
                            }
                          }}
                        >
                          <ArrowBack fontSize={isSmOrUp ? "medium" : "small"}/>
                        </IconButton>
                      </ScrollHandlerBarElementColor>
                    </Route>
                  </Switch>
                  <Box justifyContent="center" className={classes.titleLogoContainer} >
                    <ButtonBase component={RouterLink} to={categoryMap["SHOP"]}
                    onClick={() => {
                      dispatch(updateCurrentScreen("DEFAULT"));
                      (window as any).dataLayer.push({'event': 'navigateRoute', 'routePath': '/', 'routeSource': 'navBar'});
                      Mixpanel.track('navigateRoute', {
                        'routePath': '/',
                        'routeSource': 'navBar',
                      });  
                      Mixpanel.track('goToHomePage', {
                        'routeSource': 'navBar',
                      });
                      handleClickCategory();
                    }}
                    className={classes.titleLogoButtonBase}>
                      <ScrollHandlerLogo coloredAtTop={coloredAtTop} largerTitleAtTop={largerTitleAtTop} isSmOrUp={isSmOrUp} >
                        <img className={classes.titleLogo} src={'https://images-far-east.s3.ap-southeast-1.amazonaws.com/sur_monogram_minimum.svg'} alt="maja logo"/>
                      </ScrollHandlerLogo>
                    </ButtonBase>
                  </Box>
                </Box>
              </Grid>
              {/* <Grid item justifyContent="center" xs={6} md={4} className={classes.sectionDesktopAndMobile}>
                <Box justifyContent="center" className={classes.titleLogoContainer} >
                  <ButtonBase component={RouterLink} to={categoryMap["HOME"]}
                  onClick={() => {
                    dispatch(updateCurrentScreen("DEFAULT"));
                    (window as any).dataLayer.push({'event': 'navigateRoute', 'routePath': '/', 'routeSource': 'navBar'});
                    Mixpanel.track('navigateRoute', {
                      'routePath': '/',
                      'routeSource': 'navBar',
                    });  
                    Mixpanel.track('goToHomePage', {
                      'routeSource': 'navBar',
                    });
                  }}>
                    <ScrollHandlerLogo coloredAtTop={coloredAtTop} largerTitleAtTop={largerTitleAtTop} isSmOrUp={isSmOrUp} >
                      <img className={classes.titleLogo} src={process.env.PUBLIC_URL + '/studios-wordmark-final.svg'} alt="maja logo"/>
                    </ScrollHandlerLogo>
                  </ButtonBase>
                </Box>
              </Grid> */}
              <Grid item xs={2} md={2}>
                <Box className={classes.sectionDesktopAndMobile}  justifyContent="flex-end">
                  <Switch>
                    {/* <Route path="/shop/product/:productId">

                    </Route> */}
                    <Route>
                      <Box className={classes.sectionDesktopAndMobile} justifyContent="flex-start">
                        <div className={classes.buttonLinkContainer}>
                          <ScrollHandlerBarElementColor coloredAtTop={coloredAtTop} >
                            <Link component={RouterLink} to={categoryMap["SHOP"]} className={classes.buttonLink} 
                              onClick={() => {
                                (window as any).dataLayer.push({'event': 'navigateRoute', 'routePath': '/shop', 'routeSource': 'navBar'});
                                Mixpanel.track('navigateRoute', {
                                  'routePath': '/shop',
                                  'routeSource': 'navBar',
                                });
                                Mixpanel.track('goToShopPage', {
                                  'routeSource': 'navBar',
                                });
                              }}>
                              <Typography className={classes.shopText} onClick={handleClickCategory}>
                                SHOP
                              </Typography>
                            </Link>
                          </ScrollHandlerBarElementColor>
                        </div>
                      </Box>
                    </Route>
                  </Switch>
                  <Box className={classes.sectionDesktopAndMobile}>
                    <ScrollHandlerBarElementColor coloredAtTop={coloredAtTop} >
                      <IconButton 
                        className={classes.barIcon}
                        aria-label="account of current user"
                        component={RouterLink} to={categoryMap["YOUR ACCOUNT"]}
                        onClick={()=>{
                          (window as any).dataLayer.push({'event': 'navigateRoute', 'routePath': '/your-account', 'routeSource': 'navBar'});
                          Mixpanel.track('navigateRoute', {
                            'routePath': '/your-account',
                            'routeSource': 'navBar',
                          });
                          Mixpanel.track('goToAccountPage', {
                            'routeSource': 'navBar',
                          });
                        }}
                      >
                        <AccountCircleOutlinedIcon fontSize={isSmOrUp ? "medium" : "small"} />
                      </IconButton>
                    </ScrollHandlerBarElementColor>
                  </Box>
                  <ScrollHandlerBarElementColor coloredAtTop={coloredAtTop} >
                  <IconButton
                    className={classes.barIcon}
                    edge="end"
                    aria-label="open cart"
                    color="primary"
                    onClick={() => {
                      dispatch(updateCurrentScreen(navbarOverlayScreen === "CART" ? "DEFAULT" : "CART"));
                      (window as any).dataLayer.push({'event': 'navigateRoute', 'routePath': '/cart', 'routeSource': 'navBar'});
                      Mixpanel.track('navigateRoute', {
                        'routePath': '/cart',
                        'routeSource': 'navBar',
                      });
                      Mixpanel.track('cartOpen', {
                        'routeSource': 'navBar',
                      });
                    }}
                  >
                    <Badge badgeContent={numberOfItemsInCart} color="error">
                      <ShoppingCartOutlinedIcon fontSize={isSmOrUp ? "medium" : "small"} />
                    </Badge>
                  </IconButton>
                  </ScrollHandlerBarElementColor>
                </Box>
              </Grid>
            </Grid>
          </Toolbar>

          <Route exact path={["/shop", "/shop/hero/:variantHero", "/shop/category/:categoryName", "/shop/landing", "/shop/start"]}>
            <div className={classes.productNavigation}>
              <LoadingCategoryFullscreenOverlay displayLoadingOverlay={displayLoadingOverlay} />
              <div className={classes.productNavigationLeftSpacer}/>
              <Link className={classes.productNavigationContainer} color="inherit" component={RouterLink} to={"/shop"} 
              onClick={handleClickCategory}>
                <Switch>
                  <Route path="/shop/hero">
                    <Box className={classes.productNavigationLinkTextContainerSelected}>
                      <Typography variant="body2" className={classes.productNavigationLinkTextSelected}>
                        SEMUA
                      </Typography>
                    </Box>
                  </Route>
                  <Route exact path="/shop">
                    <Box className={classes.productNavigationLinkTextContainerSelected}>
                      <Typography variant="body2" className={classes.productNavigationLinkTextSelected}>
                        SEMUA
                      </Typography>
                    </Box>
                  </Route>
                  <Route exact path="/shop/category">
                    <Box className={classes.productNavigationLinkTextContainerSelected}>
                      <Typography variant="body2" className={classes.productNavigationLinkTextSelected}>
                        SEMUA
                      </Typography>
                    </Box>
                  </Route>
                  <Route>
                    <Box className={classes.productNavigationLinkTextContainer}>
                      <Typography variant="body2" className={classes.productNavigationLinkText}>
                        SEMUA
                      </Typography>
                    </Box>
                  </Route>
                </Switch>
              </Link>
              <Link className={classes.productNavigationContainer} color="inherit" component={RouterLink} to={"/shop/category/" + ProductCategory['basic-tshirt']}
              onClick={handleClickCategory}>
                <Switch>
                  <Route exact path="/shop/category/basic-tshirt">
                    <Box className={classes.productNavigationLinkTextContainerSelected}>
                      <Typography variant="body2" className={classes.productNavigationLinkTextSelected}>
                        BASIC T-SHIRTS
                      </Typography>
                    </Box>
                  </Route>
                  <Route>
                    <Box className={classes.productNavigationLinkTextContainer}>
                      <Typography variant="body2" className={classes.productNavigationLinkText}>
                        BASIC T-SHIRTS
                      </Typography>
                    </Box>
                  </Route>
                </Switch>
              </Link>
              {/* <Link color="inherit" component={RouterLink} to={"/shop/category/design-shirt"} >
                <Switch>
                  <Route exact path="/shop/category/design-shirt">
                    <Typography variant="body2" className={classes.productNavigationLinkTextSelected}>
                      Design T-Shirts
                    </Typography>
                  </Route>
                  <Route>
                    <Typography variant="body2" className={classes.productNavigationLinkText}>
                      Design T-Shirts
                    </Typography>
                  </Route>
                </Switch>
              </Link> */}
              {/* <Typography variant="body2" className={classes.productNavigationLinkTextDisabled}>
                POLO SHIRTS
              </Typography> */}
              <Link className={classes.productNavigationContainer} color="inherit" component={RouterLink} to={"/shop/category/" + ProductCategory['basic-shirt']} 
              onClick={handleClickCategory}>
                <Switch>
                  <Route exact path="/shop/category/basic-shirt">
                    <Box className={classes.productNavigationLinkTextContainerSelected}>
                      <Typography variant="body2" className={classes.productNavigationLinkTextSelected}>
                        KEMEJA KANTOR
                      </Typography>
                    </Box>
                  </Route>
                  <Route>
                    <Box className={classes.productNavigationLinkTextContainer}>
                      <Typography variant="body2" className={classes.productNavigationLinkText}>
                        KEMEJA KANTOR
                      </Typography>
                    </Box>
                  </Route>
                </Switch>
              </Link>
              <Link className={classes.productNavigationContainer} color="inherit" component={RouterLink} to={"/shop/category/batik"} 
              onClick={handleClickCategory}>
                <Switch>
                  <Route exact path="/shop/category/batik">
                    <Box className={classes.productNavigationLinkTextContainerSelected}>
                      <Typography variant="body2" className={classes.productNavigationLinkTextSelected}>
                        MODERN BATIK
                      </Typography>
                    </Box>
                  </Route>
                  <Route>
                    <Box className={classes.productNavigationLinkTextContainer}>
                      <Typography variant="body2" className={classes.productNavigationLinkText}>
                        MODERN BATIK
                      </Typography>
                    </Box>
                  </Route>
                </Switch>
              </Link>
              <Link className={classes.productNavigationContainer} color="inherit" component={RouterLink} to={"/shop/category/" + ProductCategory['polo-shirt']} 
              onClick={handleClickCategory}>
                <Switch>
                  <Route exact path="/shop/category/polo-shirt">
                    <Box className={classes.productNavigationLinkTextContainerSelected}>
                      <Typography variant="body2" className={classes.productNavigationLinkTextSelected}>
                        POLO SHIRTS
                      </Typography>
                    </Box>
                  </Route>
                  <Route>
                    <Box className={classes.productNavigationLinkTextContainer}>
                      <Typography variant="body2" className={classes.productNavigationLinkText}>
                        POLO SHIRTS
                      </Typography>
                    </Box>
                  </Route>
                </Switch>
              </Link>
              {/* <Link className={classes.productNavigationContainer} color="inherit" component={RouterLink} to={"/shop/category/" + ProductCategory['design-tshirt']}>
                <Switch>
                  <Route exact path="/shop/category/design-tshirt">
                    <Box className={classes.productNavigationLinkTextContainerSelected}>
                      <Typography variant="body2" className={classes.productNavigationLinkTextSelected}>
                        DESIGN T-SHIRTS
                      </Typography>
                    </Box>
                  </Route>
                  <Route>
                    <Box className={classes.productNavigationLinkTextContainer}>
                      <Typography variant="body2" className={classes.productNavigationLinkText}>
                        DESIGN T-SHIRTS
                      </Typography>
                    </Box>
                  </Route>
                </Switch>
              </Link> */}
              {/* <Link className={classes.productNavigationContainer} color="inherit" component={RouterLink} to={"#"}>
                <Switch>
                  <Route exact path="/shop/category/design-tshirt">
                    <Box className={classes.productNavigationLinkTextContainerSelected}>
                      <Typography variant="body2" className={classes.productNavigationLinkTextSelected}>
                        DESIGN T-SHIRTS
                      </Typography>
                    </Box>
                  </Route>
                  <Route>
                    <Box className={classes.productNavigationLinkTextContainer}>
                      <Typography variant="body2" className={classes.productNavigationLinkTextDisabled}>
                        DESIGN T-SHIRTS
                      </Typography>
                    </Box>
                  </Route>
                </Switch>
              </Link> */}
              <div className={classes.productNavigationLeftSpacer}/>
              {/* <Link className={classes.productNavigationContainer} color="inherit" component={RouterLink} to={"#"} >
                <Switch>
                  <Route exact path="/shop/category/basic-shirt">
                    <Typography variant="body2" className={classes.productNavigationLinkTextSelected}>
                      BASIC SHIRTS
                    </Typography>
                  </Route>
                  <Route>
                    <Typography variant="body2" className={classes.productNavigationLinkText}>
                      BASIC SHIRTS
                    </Typography>
                  </Route>
                </Switch>
              </Link> */}
            </div>
          </Route>
        </AppBar>
      </ScrollHandlerAppBar>

      <MobileCategoryMenu navState={navbarOverlayScreen} />
      <CartWindow navState={navbarOverlayScreen} />
    </div>
  );
}