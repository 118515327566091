import { AppBar, Link, Toolbar, Typography, Dialog, useMediaQuery, Slide } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import NavBar from './NavBar';
import {
  Route, Switch, useRouteMatch, Link as RouterLink, useLocation, useHistory,
} from "react-router-dom";
import PageProductDetail from './PageProductDetail';
import React, { useRef, useEffect } from 'react';
import PageProductHeaderVariant1 from './PageProductHeaderVariant1';
import PageProductList from './PageProductList';
import { BannerOngkir } from '../App';

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  productPageRoot: {
  },
  productListCategoryText: {
    fontWeight: "lighter",
    '&:hover': {
      fontWeight: "normal",
    },
  },
  dialogContainer: {
    zIndex: ((theme.zIndex.modal - 1) + " !important") as any, // Below ALL Modals
    width: '100vw',
  },
  dialogContentContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center', 
    textAlign: 'center',
    width: '600px',
    height: '500px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}),
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  const theme = useTheme();
  const isMdOrUp = useMediaQuery(theme.breakpoints.up("sm"));

  return <Slide direction={isMdOrUp ? "up" : "right"} ref={ref} {...props} timeout={400} />;
});

interface PageProductProps {
    
}

export default function PageProduct() {
  const classes = useStyles();

  let match = useRouteMatch();
  let history = useHistory();
  let dialogRef = useRef(null);

  const [lastProductListPathCount, setLastProductListPathCount] = React.useState<number>(0);
  const [lastCategoryString, setLastCategoryString] = React.useState('');
  const [productListPageIsVisited, setProductListPageIsVisited] = React.useState(false);

  useEffect(() => {
    if (window?.location?.href.includes('/product/')) {
      (dialogRef?.current as any)?.scrollTo({ top: 0, behavior: 'smooth' });
      setLastProductListPathCount(lastProductListPathCount + 1);
    } else {
      setLastProductListPathCount(0);
    }
  }, [window?.location?.href]);

  if (!window?.location?.href?.includes('/product') && window?.location?.href?.includes('/shop')) {

    const hrefStringArray = window.location.href.split('?')[0].split('/').filter(obj => obj?.length && obj.length > 0);
    const lastHrefString = hrefStringArray[hrefStringArray.length - 1]; 

    let currentUrlCategoryName =
      lastHrefString.includes('shop') ? '' : 
      lastHrefString.includes('start') ? 'start' : 
      lastHrefString.includes('landing') ? 'landing' : 
      hrefStringArray[hrefStringArray.length - 2] === 'category' ? lastHrefString : 
      '';

    if (lastCategoryString !== currentUrlCategoryName) {
      setLastCategoryString(currentUrlCategoryName);
    }

    if (!productListPageIsVisited) {
      setProductListPageIsVisited(true);
    }
  }

  return (
    <Box className={classes.productPageRoot}>
      <Switch>
        <Route path={`${match.path}/hero/var1`}>
          <PageProductHeaderVariant1/>
        </Route>
        <Route>
        </Route>
      </Switch>
      <Switch>
        <Route path={`${match.path}/product/:productId`}>
          <PageProductList categoryName={lastCategoryString}/>
        </Route>
        <Route path={`${match.path}/category/:categoryName`}>
          <PageProductList categoryName={(window?.location?.href as any).split('?')[0].split('/').filter((obj: any) => obj?.length && obj.length > 0).pop()} />
        </Route>
        <Route path={`${match.path}/start`}>
          <PageProductList categoryName={'start-ig'}/>
        </Route>
        <Route path={`${match.path}/landing`}>
          <PageProductList categoryName={'landing'}/>
        </Route>
        <Route path={match.path}>
          <PageProductList categoryName={''}/>
        </Route>
      </Switch>
      <Switch>
        <Route path={[`${match.path}/product/:productId`, `${match.path}`]}>
          <Dialog
          open={window?.location?.href?.includes('/product/')}
          TransitionComponent={Transition}
          keepMounted
          fullScreen
          onClose={() => {
          }}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          className={classes.dialogContainer}
          >
          <div ref={dialogRef}  
            style={{ overflowY: 'auto', height: '100%' }}>
            <NavBar transparentAtTop={false} transparent={true} coloredAtTop={false} largerTitleAtTop={false} goBackFunction={() => {
              if (productListPageIsVisited) {
                history.go(-1 * lastProductListPathCount);
              } else {
                history.push("/shop");
              }
            }}/>
            <BannerOngkir hideView={true}/>
            <Toolbar />
            <PageProductDetail />
          </div>
          </Dialog>
        </Route>
      </Switch>
    </Box>
  );
}