import { UIOverlayViewStatus } from 'common-ts/dist/models/UIOverlayViewStatus';

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface FlowVersionState {
  status: UIOverlayViewStatus,
  introHasAppeared: boolean,
  flowVersion: "" | "softintro1" | "hardintro1",
  currentFlowScreen: string,

  // Collection and sort key
  collectionQueryKey: string,
  sortQueryKey: string,
}

// Define the initial state using that type
const initialState: FlowVersionState = {
  status: UIOverlayViewStatus.OffAndReady,
  introHasAppeared: false,
  flowVersion: "",
  currentFlowScreen: "",

  collectionQueryKey: "",
  sortQueryKey: "",
}

function getStartFlowScreen(flowVersion: string) {
  if (flowVersion === "softintro1") {
    return "regulerdulu";
  }
  if (flowVersion === "hardintro1") {
    return "tanpaukur";
  }
  return "";
}

export const overlayFlowVersionViewSlice = createSlice({
  name: 'overlayFlowVersionView',
  initialState,
  reducers: {
    closeOverlayAndReset: (state) => {
      state.status = UIOverlayViewStatus.OffAndReady;

      localStorage.setItem("flowVersion", JSON.stringify(state));
      console.log("flowversion set", JSON.stringify(state));
    },

    setCurrentFlowScreen: (state, action: PayloadAction<string>) => {
      state.currentFlowScreen = action.payload;
      state.status = UIOverlayViewStatus.On;
    },

    setFlowVersion: (state, action: PayloadAction<"" | "softintro1" | "hardintro1">) => {
      // If the query value on access is non-empty (indicative of new entry),
      // we set the flow version state to the non-empty query value and display modal
      if (action.payload !== "") {
        state.flowVersion = action.payload;

        // We turn on / display the modal automatically on non-empty flowVersion change
        state.status = UIOverlayViewStatus.On;
        state.introHasAppeared = true;
        state.currentFlowScreen = getStartFlowScreen(action.payload);
      }

      localStorage.setItem("flowVersion", JSON.stringify(state));
      console.log("flowversion set", JSON.stringify(state));
    },

    loadFlowVersionState: (state, action: PayloadAction<FlowVersionState>) => {
      state.flowVersion = action.payload.flowVersion;
      state.introHasAppeared = action.payload.introHasAppeared;
      state.status = action.payload.status;
      state.currentFlowScreen = getStartFlowScreen(action.payload.flowVersion);

      console.log("flowversion loaded", JSON.stringify(state));
    },

    setCollectionQueryKey: (state, action: PayloadAction<string>) => {
      if (action.payload !== "") {
        state.collectionQueryKey = action.payload;
      }
    },

    setSortQueryKey: (state, action: PayloadAction<string>) => {
      if (action.payload !== "") {
        state.sortQueryKey = action.payload;
      }
    },
  },
  extraReducers(builder) {
  }
})

// Action creators are generated for each case reducer function
export const { closeOverlayAndReset, loadFlowVersionState, setCurrentFlowScreen,
  setFlowVersion, setCollectionQueryKey, setSortQueryKey } = overlayFlowVersionViewSlice.actions

export default overlayFlowVersionViewSlice.reducer